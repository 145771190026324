import React, { useState, useEffect, useContext } from "react";
import { storage, auth, FirebaseAuthContext } from "../context/firebase-auth";
import { ref, uploadBytes, getMetadata, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { sendPostRequest } from "../util/util";
import FileModel, { instanceOfFile } from "shared-models/build/files"
import FileType from "shared-models/build/file_type_enum";
import { File as FileIcon, Trash } from "react-feather";

interface Props {
    UploadFileType: FileType,
    Multiple: boolean,
    customDeleteHandler: Function | null, // TODO: Figure out how to set props for a function on an interface
    customAddHandler: Function | null,
    InitialFiles: FileModel[],
    setInitialFiles: Function,
    SingleFileUpload: boolean, // Only allow the user to upload one item, if they upload without deleting the other file, automatically delete the other file
    OrganizationUpload: boolean // If this is for an organization
    OrganizationId: number,
};

const FileExtensionMap = {
    [FileType.IMAGE]: ".png,.jpg,.jpeg",
    [FileType.VIDEO]: ".mp4",
    [FileType.FILE]: ".pdf,.xlsx,.xls,.doc,.docx"
};

const FileUpload = ({ UploadFileType, Multiple, customAddHandler, customDeleteHandler, InitialFiles, setInitialFiles, SingleFileUpload, OrganizationUpload, OrganizationId }: Props) => {
    const [fileInput, setFileInput] = useState<File | null>(null);
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        if (fileInput === null) return;
        uploadFile(fileInput);
    }, [fileInput])


    const uploadFile = async (fileInput: File) => {
        if (user === null) return;
        if (fileInput === null) return;
        const uniqueFileName = uuidv4();
        const lastDot = fileInput.name.lastIndexOf('.');
        const name = fileInput.name.substring(0, lastDot);
        const ext = fileInput.name.substring(lastDot + 1);
        const fileName = `${name}-${uniqueFileName}.${ext}`;
        const storageRef = ref(storage, `files/${user.uid}/${fileName}`);
        await uploadBytes(storageRef, fileInput);
        const downloadUrl = await getDownloadURL(storageRef);
        
        let tempFile : FileModel = {
            Id: 0,
            FileName: fileName,
            UserId: 0,
            FileUrl: downloadUrl,
            FileType: UploadFileType,
            Settings: {},
            OrgId: 0,
        };

        // Temp Files
        const tempFiles = [...InitialFiles];

        // Send to backend to add to the table
        let err = null; 
        if (OrganizationUpload) {
            const [fileData, err] = await sendPostRequest(user, "/files/add-file", tempFile, "", "Failed to add the file, please contact our team at hello@mycloudsafari.com");
            if (err !== null) {
               console.log({ fileData, err });
               return; 
            } else if (instanceOfFile(fileData)) {
                setInitialFiles((prevState : FileModel[]) => {
                    return [
                        ...prevState,
                        fileData,
                    ]
                })

                // Add to the organization file table
                const [newFileData, err] = await sendPostRequest(user, "/files/add-organization-file", { AttachedFile: fileData, OrgId: OrganizationId }, "", "Failed to add the file, please contact our team at hello@mycloudsafari.com");
                if (err !== null) {
                   console.log({ newFileData, err });
                   return; 
                }
                
                tempFile = fileData;
            }
        } else {
            const [fileData, err] = await sendPostRequest(user, "/files/add-file", tempFile, "", "Failed to add the file, please contact our team at hello@mycloudsafari.com");
            if (err !== null) {
               console.log({ fileData, err });
               return; 
            } else if (instanceOfFile(fileData)) {
                setInitialFiles((prevState : FileModel[]) => {
                    return [
                        ...prevState,
                        fileData,
                    ]
                })
                tempFile = fileData;
            }
        }


        // Trigger Custom Add Handler
        if (customAddHandler !== null) {
            customAddHandler(tempFile);
        }        

        // Clear File Upload
        setFileInput(null);
    }

    useEffect(() => {
        if (SingleFileUpload && InitialFiles.length > 1) {
            handleDelete(InitialFiles[0], 0);
        }
    }, [InitialFiles]);

    const handleDelete = async (tempFile: FileModel, index: number) => {
        if (user === null) return;

        // Delete from Firebase & Backend
        if (OrganizationUpload) {
            const [delFile, err] = await sendPostRequest(user, "/files/delete-organization-file", tempFile, "", "Failed to delete the file.");
            if (err !== null) {
                console.log({ delFile, err });
            }
        } else {
            const [delFile, err] = await sendPostRequest(user, "/files/delete-file", tempFile, "", "Failed to delete the file.");
            if (err !== null) {
                console.log({ delFile, err });
            }
        }

        setInitialFiles((prevState : FileModel[]) => {
            const tempFiles = [...prevState];
            tempFiles.splice(index, 1);

            const newFiles = [...tempFiles];

            // Trigger Custom Delete Handler
            if (customDeleteHandler !== null) {
                customDeleteHandler(tempFile, newFiles);
            }
            
            return newFiles;
        })
    }

    return (
        <div className="tw-p-1 tw-text-gray-400">
            <input type="file" accept={FileExtensionMap[UploadFileType]} multiple={Multiple} onChange={(event) => {
                let file = event.target.files
                if (file === null) return; //looking if the arrary is null (not even initialized)
                if (file.length === 0) return; //looking if the array that isn't null but empty (no values)
                setFileInput(file[0]);
            }}
            />
            <div className="tw-flex tw-gap-4 tw-flex-wrap tw-mt-4">
                {
                    InitialFiles.map((val: FileModel, index: number) => {
                        if (!(instanceOfFile(val))) return null;
                        return (
                            <div className="tw-flex tw-flex-col tw-justify-around tw-items-center tw-gap-4 tw-border-[1px] tw-p-2 tw-border-gray-300 tw-rounded">
                                {
                                    val.FileType === FileType.IMAGE ?
                                    <img src={val.FileUrl} className="tw-w-[100px] tw-h-[100px] tw-object-contain" />
                                    :
                                    val.FileType === FileType.VIDEO ?
                                    <video className="tw-w-[800px] tw-h-auto tw-object-contain">
                                        <source src={val.FileUrl} />
                                    </video>
                                    :
                                    <FileIcon />
                                }
                                <div className="tw-flex tw-justify-between">
                                    <a className="tw-text-xs tw-max-w-[300px] tw-break-words tw-text-blue-600">{val.FileName}</a>
                                    <button onClick={() => handleDelete(val, index)} className="tw-bg-red-500 tw-rounded tw-text-white tw-p-2 tw-max-w-[100px]"><Trash size={12} className="tw-text-white"/></button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

export {
    FileUpload,
}