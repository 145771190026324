import React, { useContext, useEffect, useState } from "react";
import ClientOperatorMessage, { ClientOperatorFiles, getDefaultClientOperatorFiles, getDefaultClientOperatorMessage, instanceOfClientOperatorFiles, instanceOfClientOperatorMessage } from "shared-models/build/client/operators";
import { FileUpload } from "../../../components/FileUpload";
import { sendGenericPostRequest, sendPostRequest, toastSuccess } from "../../../util/util";
import Calender from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import ClientCountryMessage, { instanceOfClientCountryMessage } from "shared-models/build/client/countries";
import Select from "react-select";
import ClientActivityMessage, { ClientCountryActivities, instanceOfClientActivityMessage, instanceOfClientCounryActivities } from "shared-models/build/client/activities";
import { FirebaseAuthContext } from "../../../context/firebase-auth";
import FileModel from "shared-models/build/files";
import FileType from "shared-models/build/file_type_enum";


interface Props {
    OrgId: number,
}

interface SelectOptions {
    value: number,
    label: string,
}

const Profile = ({ OrgId }: Props) => {
    const [operatorDetails, setOperatorDetails] = useState<ClientOperatorMessage>(getDefaultClientOperatorMessage());
    const [logoFiles, setLogoFiles] = useState<FileModel[]>([]);
    const [videoFiles, setVideoFiles] = useState<FileModel[]>([]);
    const [imageFiles, setImageFiles] = useState<FileModel[]>([]);

    // Country Values
    const [countries, setCountries] = useState<{ [key: number]: ClientCountryMessage }>([]);
    const [countrySelectOptions, setCountrySelectOptions] = useState<SelectOptions[]>([]);
    const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
    const [initialCountryValues, setInitialCountryValues] = useState<SelectOptions[]>([]);

    // Activity Values
    const [activities, setActivities] = useState<{ [key: number]: ClientActivityMessage[] }>([]);
    const [selectedActivities, setSelectedActivities] = useState<number[]>([]);

    const user = useContext(FirebaseAuthContext);
    
    useEffect(() => {
        getCountries();
        getActivities();
    }, [])

    useEffect(() => {
        getOperator();
    }, [user])

    useEffect(() => {
        const init = selectedCountries.reduce((arr: any[], val : number) => {
            if (val in countries) {
                return [
                    ...arr,
                    { value: val, label: countries[val].Name }
                ];
            }
            return arr;
        }, [])
        setInitialCountryValues(init);
    }, [selectedCountries, countries])

    const getOperator = async () => {
        const [op, opErr] = await sendGenericPostRequest("/organization/get-operator-with-id", { OperatorId: OrgId }, "", "Failed to get the organization data.");
        if (opErr !== null) {
            console.error(op, opErr);
            return;
        }
        if (!instanceOfClientOperatorMessage(op)) {
            console.error("Not a Client operator message.", op);
            return;
        }
        setOperatorDetails(op);
        const numericIds = Object.keys(op.Countries).reduce((arr : number[], val) => {
            return [
                ...arr,
                +String(val)
            ];
        }, []);
        setSelectedCountries(numericIds);
        const numericActivityIds = Object.keys(op.Activities).reduce((arr: number[], val) => {
            return [...arr, +String(val)];
        }, []);
        setSelectedActivities(numericActivityIds);

        if (user === null) return;
        const [opFiles, opFilesErr] = await sendPostRequest(user, "/organization/get-operator-files", { OperatorId: OrgId }, "", "Failed to get the operator files");
        if (opFilesErr) {
            console.error(opFiles, opFilesErr);
            return;
        }
        if (!instanceOfClientOperatorFiles(opFiles)) return;
        setLogoFiles([opFiles.Logo]);
        setVideoFiles([opFiles.Video]);
        setImageFiles(opFiles.Images);
    }

    const getCountries = async () => {
        const [country, countryErr] = await sendGenericPostRequest("/country/get-verified-countries", {}, "", "Failed to get country and activity data.");
        if (countryErr !== null) {
            console.error(country, countryErr);
            return;
        }
        if (!Array.isArray(country)) return;
        const countryMap = country.reduce((obj : { [key: string]: ClientCountryMessage }, val : ClientCountryMessage) => {
            if (!instanceOfClientCountryMessage(val)) return obj;
            return {
                ...obj,
                [val.CountryId]: val,
            };
        }, {});
        const countrySelection = country.reduce((arr : [], val : ClientCountryMessage) => {
            if (!instanceOfClientCountryMessage(val)) return arr;
            return [
                ...arr,
                { value: val.CountryId, label: val.Name }
            ];
        }, []);        
        setCountries(countryMap);
        setCountrySelectOptions(countrySelection);
    }

    const getActivities = async () => {
        const [activityFetch, activityFetchErr] = await sendGenericPostRequest("/activity/get-verified-activities", {}, "", "Failed to get the activities.");
        if (activityFetchErr !== null) {
            console.error(activityFetch, activityFetchErr);
            return;
        }
        if (!Array.isArray(activityFetch)) return;
        const activityObj = activityFetch.reduce((obj: { [key: number]: ClientActivityMessage[] }, val) => {
            if (!instanceOfClientCounryActivities(val)) return obj;
            return {
                ...obj,
                [val.CountryId]: val.Activity
            }
        }, {});
        setActivities(activityObj);
    }

    const updateOrgFile = async (AttachedFile: FileModel, path : string) => {
        if (user === null) return;
        const [update, err] = await sendPostRequest(user, path, { OperatorId: OrgId, AttachedFile }, "", "Failed to update the file for the org.");
        if (err !== null) {
            console.error(update, err);
            return;
        }
    }

    const saveProfile = async () => {
        if (user === null) return;
        const countryKeys = selectedCountries.reduce((obj: { [key: number]: boolean }, val) => {
            return {
                ...obj,
                [val]: true,
            };
        }, {});
        const tempOpDetails = { ...operatorDetails };
        tempOpDetails.Countries = countryKeys;
        const [update, err] = await sendPostRequest(user, "/organization/save-operator-profile", tempOpDetails, "", "Failed to save the data");
        if (err !== null) {
            console.error(update, err);
            return;
        }
        toastSuccess("Saved organization profile.")
    }

    return (
        <div className="tw-max-w-screen tw-relative">
            <h3 className="tw-flex tw-flex-col tw-text-lg tw-font-bold tw-mb-3">Edit your Organization Details Here</h3>
            <div className="tw-grid md:tw-grid-cols-2 tw-gap-8 tw-mb-8 tw-mt-8">
                <div className="tw-w-full">
                    <p className="tw-mb-2 tw-font-medium">Organization name</p>
                    <div>
                        <input value={operatorDetails.OrgName} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                OrgName: e.target.value
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
                <div className="tw-w-full">
                    <p className="tw-mb-2 tw-font-medium">Number of vehicles</p>
                    <div>
                        <input value={operatorDetails.NumberOfVehicles} 
                        type="number"
                        onChange={(e) => {
                            const val = +String(e.target.value);
                            if (typeof val !== "number" || isNaN(val)) return;
                            setOperatorDetails({
                                ...operatorDetails,
                                NumberOfVehicles: val,
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
                <div className="tw-w-full">
                    <p className="tw-font-medium">Team size</p>
                    <small>Number of people on your team including yourself (drivers, staff, cooks, directors, etc.)</small>
                    <div className="tw-mt-2">
                        <input value={operatorDetails.TeamSize} 
                        type="number"
                        onChange={(e) => {
                            const val = +String(e.target.value);
                            if (typeof val !== "number" || isNaN(val)) return;
                            setOperatorDetails({
                                ...operatorDetails,
                                TeamSize: val,
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
                <div className="tw-w-full">
                    <p className="tw-font-medium">Age range</p>
                    <small>What age range is best suited for your tours (I.e. Best suited for travelers aged 29-34)?</small>
                    <div className="tw-mt-2">
                        <input value={operatorDetails.AgeRange} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                AgeRange: e.target.value
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
                <div className="tw-w-full">
                    <p className="tw-font-medium">Website</p>
                    <div className="tw-mt-2">
                        <input value={operatorDetails.Website} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                Website: e.target.value
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-mb-8">
                <div className="tw-w-full">
                    <p className="tw-font-medium">Tour type</p>
                    <small>What kind of tours do your company offer (I.e. Budget and Mid-range Safari's)?</small>
                    <div className="tw-mt-2">
                        <input value={operatorDetails.TourType} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                TourType: e.target.value
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-mb-8">
                <div className="tw-w-full">
                    <p className="tw-font-medium">What makes you the best tour operator?</p>
                    <small>In one sentence, tell us what makes your company special.</small>
                    <div className="tw-mt-2">
                        <input value={operatorDetails.ShortDescription} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                ShortDescription: e.target.value
                            })
                        }}
                        className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
            </div>
            <div className="tw-flex tw-mb-8">
                <div className="tw-w-full">
                    <p className="tw-font-medium">Tell us about yourself</p>
                    <small>Write a longer description description you, and your company.</small>
                    <div className="tw-mt-2">
                        <textarea value={operatorDetails.AboutUs} 
                        onChange={(e) => {
                            setOperatorDetails({
                                ...operatorDetails,
                                AboutUs: e.target.value
                            })
                        }}
                        rows={8} className="tw-border tw-rounded tw-w-full"  />
                    </div>
                </div>
            </div>

            <div className="tw-mb-8">
                <p className="tw-mb-2 tw-font-medium">Founded Date</p>
                <Calender className="tw-border-2 tw-rounded-lg tw-shadow-lg tw-text-gray-500" 
                value={new Date(operatorDetails.FoundedDate)}
                onChange={(dateVal : Date) => {
                    setOperatorDetails({
                        ...operatorDetails,
                        FoundedDate: dateVal,
                    })
                }}
                />
            </div>

            <div className="tw-flex tw-mb-8">
                <div className="tw-w-full">
                    <p className="tw-font-medium">What countries do you operate in?</p>
                    <small>Select the countries you operate in.</small>
                    <div className="tw-mt-2">
                        <Select
                        value={initialCountryValues}
                        isMulti className="basic-multi-select" options={countrySelectOptions} onChange={(newValue) => {
                            const ids = newValue.reduce((arr : number[], obj) => {
                                if ("value" in obj) {
                                    if (typeof obj.value === "number") {
                                        return [...arr, obj.value];
                                    }
                                }
                                return arr;
                            }, []);
                            setSelectedCountries(ids);
                        }} />
                    </div>
                </div>
            </div>

            { /* TODO: Implement Activity Selection
                initialCountryValues.map((val) => {
                    if (!(val.value in activities)) return null;
                    const selectMap = activities[val.value];
                    const vals = Object.values(selectMap);

                    return (
                        <div className="tw-mb-4">
                            <p className="tw-font-medium">What activities do you handle in {val.label}?</p>
                            <small>Select all activities your business can do.</small>
                            <Select
                            isMulti className="basic-multi-select"
                            getOptionLabel={option => option.Name}
                            getOptionValue={option => String(option.ActivityId)}
                            options={vals}
                            onChange={(newVals) => {
                                console.log(newVals);
                            }}
                            />
                        </div>
                    )
                })
                */
            }

            <div className="tw-mb-8">
                <p className="tw-mb-2 tw-font-medium">Logo</p>
                <div>
                    <FileUpload 
                        InitialFiles={logoFiles}
                        setInitialFiles={setLogoFiles}
                        Multiple={false}
                        customAddHandler={(tempFile : FileModel) => updateOrgFile(tempFile, "/organization/update-org-avatar-url")}
                        customDeleteHandler={null}
                        SingleFileUpload={true}
                        OrganizationUpload={true}
                        UploadFileType={FileType.IMAGE}
                        OrganizationId={OrgId}
                    />
                </div>
            </div>
            <div className="tw-mb-8">
                <p className="tw-mb-2 tw-font-medium">Operator Video</p>
                <div>
                    <FileUpload 
                        InitialFiles={videoFiles}
                        setInitialFiles={setVideoFiles}
                        Multiple={false}
                        customAddHandler={(tempFile : FileModel) => updateOrgFile(tempFile, "/organization/update-org-video")}
                        customDeleteHandler={null}
                        SingleFileUpload={true}
                        OrganizationUpload={true}
                        UploadFileType={FileType.VIDEO}
                        OrganizationId={OrgId}
                    />
                </div>
            </div>
            <div className="tw-mb-8">
                <p className="tw-mb-2 tw-font-medium">Main Profile Image</p>
                <div>
                    <FileUpload 
                        InitialFiles={imageFiles}
                        setInitialFiles={setImageFiles}
                        Multiple={false}
                        customAddHandler={(tempFile : FileModel) => updateOrgFile(tempFile, "/organization/update-org-main-image")}
                        customDeleteHandler={null}
                        SingleFileUpload={true}
                        OrganizationUpload={true}
                        UploadFileType={FileType.IMAGE}
                        OrganizationId={OrgId}
                    />
                </div>
            </div>

            <button className="tw-primary-button tw-fixed tw-bottom-[20px] tw-right-[20px]" onClick={() => saveProfile()}>
                Save Organization Details
            </button>
        </div>
    )
};

export default Profile;