"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfClientCounryActivities = exports.instanceOfClientActivityMessage = void 0;
;
const instanceOfClientCounryActivities = (object) => {
    return ('CountryName' in object &&
        'CountryId' in object &&
        'Activity' in object);
};
exports.instanceOfClientCounryActivities = instanceOfClientCounryActivities;
const instanceOfClientActivityMessage = (object) => {
    return ('Name' in object &&
        'Description' in object &&
        'Tags' in object &&
        'ImageURL' in object &&
        'BlogPostLink' in object &&
        'ActivityId' in object);
};
exports.instanceOfClientActivityMessage = instanceOfClientActivityMessage;
